<template>
  <f7-page>
    <f7-navbar title="Urbi V2" back-link="Home"></f7-navbar>


    <f7-block no-hairlines style="padding:0; margin:0;">
      <p class="text-align-center">
        <img src="https://app.urbigo.me/uploads/slika3.png" style="width: 100%;">
      </p>
    </f7-block>

    <f7-block inset style="margin-bottom: 0;">
      <f7-block-title medium class="center-item fix-title">Something Special Just for You</f7-block-title>
      <p>
        While your current Urbi continues to thrive, new Urbi introduces exciting new features:<br/>
        <ul>
          <li>8 planting slots</li>
          <li>Modular design</li>
          <li>Adaptive grow lights</li>
          <li>Completely new gamified app</li>
          <li>Water sensors with multiple levels of precision</li>
        </ul>
        The new app will also be fully compatible with V1! <br/><br/>
        Together, we're on a mission to bring nature closer to the urban generation and make
        food gardening fun and simple for everyone! <br/><br/>
        Explore the Urbi Gardening Station now and secure exclusive launch discounts. <br/><br/>
      </p>
    </f7-block>

    <f7-row tag="p">
      <f7-col tag="span">
        <f7-button
            href="https://www.kickstarter.com/projects/1463957959/urbi-an-interactive-indoor-gardening-station?utm_source=urbiapp&utm_medium=organic" external
            target="_blank"
            style="color:white; background-color:#028858; margin:1em;" large raised fill round>
          🔥 Check Out the New Urbi 🔥
        </f7-button>
      </f7-col>
    </f7-row>


  </f7-page>
</template>

<script>
export default {
  name: "AboutUs",
  data() {
    return {}
  },
}
</script>

<style scoped>
.img-border-app-icon {
  border: solid 1px #cdcdcd;
  border-radius: 25px;
  width: 120px;
}

.icon {
  border: 0px solid #f05e2c;
  padding: 0px 15px;

  font-size: 36px;
  width: 36px;
  height: 36px;
}

.fix-title{
  white-space: normal;
  overflow: visible;
  text-overflow: unset;
}


</style>
