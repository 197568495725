import Tab1 from '../pages/tab1.vue';
import Tab2 from '../pages/tab2.vue';
import Tab3 from '../pages/tab3.vue';
import Tab4 from '../pages/tab4.vue';

// Public
import Login from '../pages/public/Login';
import SplashScreens from '../pages/public/SplashScreens'
import Register from '../pages/public/Register'
import ForgotPassword from '../pages/public/ForgotPassword'
import TandC from '../pages/public/Tandc'

// Home
import EditGarden from '../pages/home/EditGarden';
import SetupDevice from '../pages/home/SetupDevice';
import UserNotifications from '../pages/home/UserNotifications';
import NewAnnouncement from '../pages/home/NewAnnouncement.vue';

// Notifications
import NotiWelcome from '../pages/notifications/Welcome';
import NotiLightsOn from '../pages/notifications/LightsOn';
import NotiManualLights from '../pages/notifications/ManualLights';
import NotiRemoveTransparentDomes from '../pages/notifications/RemoveTransparentDomes';
import NotiWaterWarning from '../pages/notifications/WaterWarning';
import FeatureNotifications from '../pages/notifications/FeatureNotifications'
import PullDowntheLights from '../pages/notifications/PullDowntheLights'
import WaterLevelDebug from '../pages/notifications/WaterLevelDebug'
import HighestLightBarLevel from '../pages/notifications/HighestLightBarLevel'

// Community
import AddToWall from '../pages/community/AddToWall';


// Learn
import WaterInfo from '../pages/learn/WaterInfo';
import TemperatureInfo from '../pages/learn/TemperatureInfo';
import LidInfo from '../pages/learn/LidInfo';
import LightInfo from '../pages/learn/LightInfo';
import CleaningInfo from '../pages/learn/CleaningInfo';
import CapsulesInfo from '../pages/learn/CapsulesInfo';

// Settings
import AboutUs from '../pages/settings/aboutUs.vue';
import ReadManual from '../pages/settings/ReadManual.vue';
import Feedback from '../pages/settings/Feedback.vue';
import ResetCapsules from '../pages/settings/ResetCapsules.vue';
import Delete from '../pages/settings/Delete.vue';
import Unlink from '../pages/settings/Unlink.vue';
import Notifications from '../pages/settings/Notifications.vue';
import ProfilePassword from '../pages/settings/ProfilePassword.vue';
import Profile from '../pages/settings/Profile.vue';
import AddUrbiGo from '../pages/settings/AddUrbiGo.vue';

// 404
import NotFoundPage from '../pages/404.vue';
import Vue from "vue";

// -------------- Resole HOST
let host = location.protocol + '//' + location.hostname;
if (host == 'http://localhost') {
  host = 'http://urbigo-app.test/api/';
}
else {
  host = 'https://app.urbigo.me/api/';
}
const token = localStorage.getItem('token')
const skipScreens = ['home', 'learn', 'community', 'settings']
// -------------- Business logic


function beforeEach(to, from, resolve, reject) {
  // for non public routes, if there is no token at all redirect user to the login
  if (
    to.name !== 'login' && to.name !== 'splash' && to.name !== 'register' &&
    to.name !== 'forgot-password' && to.name !== 't-and-c' && to.name !== 'not-found'
  ) {

    if (!localStorage.getItem('token')) {
      const router = this;
      reject();
      router.navigate('/login');
      return;
    }

    // Collecting the data on every screen
    // When user is logged in, collect screen changes (exclude tabs, that we will be collected in app.vue - on tab show)
    // We should use mixin's collect function, but it isn't available.
    if(!skipScreens.includes(to.name)) {
      if (this.app !== undefined && this.app) {
        this.app.request.setup({headers: {'Authorization': 'Bearer ' + token}})
        this.app.request.get(host + 'collect', {
          'event': 'LOAD_SCREEN',
          'device_id': null,
          'params': {'screen': to.name}
        })
      }
    }
  }

  resolve();
}

let routes = [
  // public routes
  {name: 'login', path: '/login', component: Login, options: {transition: 'f7-circle'}},
  {name: 'splash', path: '/splash', component: SplashScreens, meta: {title: 'Welcome'}},

  {name: 'register', path: '/register', component: Register, meta: {title: 'Create account'}},
  {name: 'forgot-password', path: '/forgot-password', component: ForgotPassword, meta: {title: 'Forgot password'}},
  {name: 't-and-c', path: '/terms-and-conditions', component: TandC, meta: {title: 'Terms and conditions'}},

  // main tabbed main screen
  {name: 'home', path: '/tab1/', component: Tab1},
  {name: 'community', path: '/tab2/', component: Tab2},
  {name: 'learn', path: '/tab3/', component: Tab3},
  {name: 'settings', path: '/tab4/', component: Tab4},

  // home
  {name: 'edit-garden', path: '/edit-garden/', component: EditGarden},
  {name: 'setup-device', path: '/setup-device/', component: SetupDevice, options: {transition: 'f7-circle'}},
  {name: 'user-notifications', path: '/user-notifications/', component: UserNotifications},
  {name: 'new-announcement', path: '/new-announcement/', component: NewAnnouncement},

  // notifications
  {name: 'noti-welcome', path: '/noti-welcome/', component: NotiWelcome},
  {name: 'noti-lights-on', path: '/noti-lights-on/', component: NotiLightsOn},
  {name: 'noti-manual-lights', path: '/noti-manual-lights/', component: NotiManualLights},
  {name: 'noti-water-warning', path: '/noti-water-warning/', component: NotiWaterWarning},
  {name: 'noti-remove-transparent-domes', path: '/noti-remove-transparent-domes/', component: NotiRemoveTransparentDomes},
  {name: 'feature-notifications', path: '/feature-notifications/', component: FeatureNotifications},
  {name: 'pull-down-lights', path: '/pull-down-lights/', component: PullDowntheLights},
  {name: 'water-level-debug', path: '/water-level-debug/', component: WaterLevelDebug},
  {name: 'highest-light-bar-level', path: '/highest-light-bar-level/', component: HighestLightBarLevel},

  // community
  {name: 'add-to-wall', path: '/add-to-wall/', component: AddToWall},

  // learn
  {name: 'water-level-sensor', path: '/water-level-sensor/', component: WaterInfo},
  {name: 'temperature', path: '/temperature/', component: TemperatureInfo},
  {name: 'lid', path: '/lid/', component: LidInfo},
  {name: 'light-info', path: '/light-info/', component: LightInfo},
  {name: 'cleaning-info', path: '/cleaning-info/', component: CleaningInfo},
  {name: 'capsules-info', path: '/capsules-info/', component: CapsulesInfo},

  // settings
  {name: 'about-us', path: '/about-us/', component: AboutUs},
  {name: 'read-manual', path: '/read-manual/', component: ReadManual},
  {name: 'feedback', path: '/feedback/', component: Feedback},
  {name: 'reset-capsules', path: '/reset-capsules/', component: ResetCapsules},
  {name: 'delete', path: '/delete/', component: Delete},
  {name: 'unlink', path: '/unlink/', component: Unlink},
  {name: 'notifications', path: '/notifications/', component: Notifications},
  {name: 'profile', path: '/profile/', component: Profile},
  {name: 'password', path: '/password/', component: ProfilePassword},
  {name: 'add-urbigo', path: '/add-urbigo/', component: AddUrbiGo},

  // --- 404
  {name: 'not-found', path: '(.*)', component: NotFoundPage}
];


// Glue logic on every route
const l = routes.length;
for (let i = 0; i < l; i++) {
  routes[i].beforeEnter = beforeEach;
}

export default routes;